<div class="container">
  <h1 class="text-center mb-4">EXPOSITIE</h1>
  <div class="row">
    <div class="col-md-6 col-12">
      <img class="w-100" src="/assets/images/expositie.jpg" />
    </div>
    <div class="col-md-6 col-12">
      Het is zover, Naald & Hamer geeft zich bloot! En wel op een bijzondere en
      toepasselijke locatie: Pont 20, die vroeger vertrok vanaf de Distelweg.
      Wij van Naald & Hamer zijn uitgenodigd om hier onze kunsten te vertonen.
      Dit is onze allereerste expositie, en dat meteen solo! Alles mag gezien
      worden, van onze vroege geometrische werken tot ons sterkste staaltje
      Naaktstaal. Op vrijdag 5 november om 18.00 gaat de klep omlaag en is
      iedereen welkom voor een hapje en een drankje bij ons visuele feestje. Op
      zaterdag en zondag zullen we van 12.00 tot 19.00 de klep openen. Komt dat
      zien komt dat zien komt dat zien!

      <p>
        Pont 20 ligt bij Kaap de Groene Hoop op de NDSM, op de Ms. van Riemsdijkweg 310
    </p>
    </div>
  </div>
</div>
