<div class="container">
  <h1 class="text-center mb-4">ARTIS</h1>

  <p class="text-center mb-4 mx-6">
    Wij zijn door <a href="https://www.zandraket.nl" target="_blank">Zandraket</a> Natuurspeeltuinen gevraagd om 25 bogen te maken van
    Cortenstaal, om een tunnel te creëren voor de nieuwe natuurspeeltuin van
    ARTIS : ) We hebben de bogen gemaakt in onze werkplaats bij de Vliegtuin, en
    hebben ze ter plekke aan elkaar gelast.
  </p>

<div class="row justify-content-center">
  <div class="col-lg-4 col-12 mb-4">
    <img class="w-100" src="assets\images\projecten\artis2.jpg" />
  </div>
  <div class="col-lg-4 col-12 mb-4">
    <img class="w-100" src="assets\images\projecten\artis1.jpg" />
  </div>
  <div class="col-lg-4 col-12 mb-4">
    <img class="w-100" src="assets\images\projecten\artis3.jpg" />
  </div>
</div>
</div>
