
<div class="container">
  <h1 class="text-center mb-4">
  HORNBACH
</h1>

<div class="row justify-content-center mb-5 h-100">
  <div class="col-lg-5 col-12 filmpje">
    <iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/XpXuhCqYLpc"
      title="Die besten Quadratmeter der Welt - Folge 06 / Niederlande | Der BBQ-Turm"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  </div>

  <div class="col-lg-5 col-12 flex-column flexything justify-content-between">
    <!--<img class="w-100" src="/assets/images/projecten/bbq5.jpg" /> -->
    <div class="align-self-start">
      <p>Voor de internationale sociale mediacampagne van de HORNBACH  kregen we de vraag;  Wat zou jij doen met een vierkante meter? Wij dachten meteen: Vuur! Hoog! Beweging!</p>

      <p>Zodoende hebben we een stalen frame gemaakt dat we spelenderwijs hebben ingevuld. Al snel wisten we dat het een barbecue moest worden, met een grillende krukas,
        aardappelmandjes die omhoog en omlaag kunnen, een as die kan ronddraaien op de wind, en een ronddraaiende fietswielgril.
        Hoe meer we door onze schroothoop harkten, hoe meer ideeën we kregen. Dus dit is waarom we zo’n grote staalverzameling hebben ;) </p>

<p>Ziehier het resultaat!</p>


    </div>
    <div class="align-self-end">
      <!-- Credits:
      - bla
      -bla -->
    </div>
  </div>

</div>

<div class="row justify-content-center">
  <div class="col-lg-4 col-12 mb-4">
    <img class="w-100" src="/assets/images/projecten/bbq4.jpg" />
  </div>
  <div class="col-lg-4 col-12 mb-4">
    <img class="w-100" src="/assets/images/projecten/bbq1.png" />
  </div>
  <!-- <div class="col-3">
    <img class="w-100" src="/assets/images/projecten/bbq2.png" />
  </div> -->
  <div class="col-lg-4 col-12 mb-4">
    <img class="w-100" src="/assets/images/projecten/bbq3.png" />
  </div>
</div>
</div>
