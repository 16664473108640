<div class="container">
  <h1 class="text-center mb-4">
    TEXTIEL
  </h1>
  <p class="text-center mb-4">
    Van textiel maken wij grote en kleine dingen.
  </p>
  <app-gallery [imageLocations]="images" [imageBaseUrl]="baseImageUrl">
  </app-gallery>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-8 mt-3">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/6GbWetbF3mA" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>
