import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opverzoek',
  templateUrl: './opverzoek.component.html',
  styleUrls: ['./opverzoek.component.css']
})
export class OpverzoekComponent implements OnInit {
  public baseImageUrl = 'assets/images/opverzoek/';
  public images = ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg', '9.jpg', '11.jpg', '12.jpg', '13.jpg',
'14.jpg', '15.jpg', '17.jpg', '18.jpg', '19.jpg', '20.jpg'
];

  constructor() { }

  ngOnInit() {
  }

}
