import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wiewijzijn',
  templateUrl: './wiewijzijn.component.html',
  styleUrls: ['./wiewijzijn.component.scss']
})
export class WiewijzijnComponent implements OnInit {
  public baseImageUrl ='assets/images/wiewijzijn/'
  public images = ["pieremegoggel.jpg",
     "overons.jpg",
     "atelier.jpg",
     "biertjeverdiend.jpg",
      "tietenbed.jpg",
      "film.jpg",
      "kraken.jpg",
      "tietkandelaars.jpg",
      "christelfilm.jpg",
      "riannefilm.jpg",
      "traphek.jpg",
      "christelbed.jpg",
      "driehoeklassen.jpg",
      "geometrie.jpg",
      "vuurdame.jpg",
      "hoogtelassen.jpg",
      "kleurtjes.jpg",
      "koffie.jpg",
      "boren.jpg",
      "lassen.jpg",
      "overons.jpg",
      "pieremegoggel.jpg",
      "rakeklappen.jpg",
      "shovel.jpg",

    ]
  constructor() { }

  ngOnInit(): void {
  }

}
