import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-koffiebar',
  templateUrl: './koffiebar.component.html',
  styleUrls: ['./koffiebar.component.scss']
})
export class KoffiebarComponent implements OnInit {
  public imageBaseUrl = 'assets/images/koffiebar/';
  public images = [
    'koffiebynight.jpg',
    'Bar-kopie.jpg',
    'koffiezetten.jpg',
    'machine.jpg',
    'koffieart.jpg',
    'bar2.jpg',
    'machine2.jpg',
    'jaques.jpg',
    'paneel.jpg',
    'machinekat.jpg',
    'paneel2.jpg',
    'koffiesky.jpg',
    'machine3.jpg',
    'silvia2weldingfinished.jpg',
    'silviA2word3d.jpg',
    'vijfhoekpaneel-begin.jpg'
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
