<div *ngIf="!authenticatedUser">
  <div class="form-group">
    <input
      type="text"
      class="form-control"
      id="exampleFormControlInput1"
      placeholder="email"
      [formControl]="username"
    />
    <input
      type="text"
      class="form-control"
      id="exampleFormControlInput1"
      placeholder="password"
      type="password"
      [formControl]="password"
    />
  </div>
  <button type="button" class="btn btn-primary" (click)="login()">LOGIN</button>
</div>
<div class="" *ngIf="authenticatedUser">
  <a class="d-flex my-2" routerLink="/edit/dieren">Edit dieren</a>
  <a class="d-flex my-2"routerLink="/edit/naakt">Edit naakt</a>
  <a class="d-flex my-2" routerLink="/edit/divers">Edit geometrish</a>
</div>
<button
  *ngIf="authenticatedUser"
  type="button"
  class="btn btn-primary"
  (click)="signOut()"
>
  SIGNOUT
</button>
