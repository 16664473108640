<div class="container-fluid">
  <h1 class="text-center mb-4">
    CONTACT
  </h1>
  <h2 class="text-center mb-4"></h2>

  <div class="text-center">
    Christel van Schoonhoven & Rianne Jasper<br />
    info@naaldenhamer.nl<br />
    +31630043988<br />
  </div>
  <br />
  <p class="text-center">
    Wij staan open voor allerlei verzoeken. Neem contact op en wees welkom voor
    een kopje koffie in ons atelier! Wij zitten bij De Vliegtuin aan de
    Distelweg in Amsterdam Noord.
  </p>
</div>
