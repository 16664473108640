import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  public modalRef: BsModalRef;
  public activeImage = 0;
  @Input() imageLocations: string[];
  @Input() imageBaseUrl: string;
  @ViewChild('lgModal') templateRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  openCarroussel(index: number) {
    if (window.innerWidth > 800) {
      this.modalRef = this.templateRef.show();
      this.activeImage = index;
      console.log(this.activeImage);
    }
  }


}
