<div class="modal-header">Voeg Diertje toe</div>
<div class="modal-body">
  <div class="form-group">
    <input
      type="text"
      class="form-control"
      id="exampleFormControlInput1"
      placeholder="name"
      [formControl]="name"
    />
    <input
    type="text"
    class="form-control"
    id="exampleFormControlInput1"
    placeholder="description voor google"
    [formControl]="alttext"
  />  </div>
  <div class="row">
    <div class="custom-file col py-2 mx-4">
      <input
        type="file"
        class="custom-file-input col"
        id="customFile"
        (change)="onStaalChange($event.target.files)"
        required
      />
      <label class="custom-file-label" for="customFile">{{
        staalUploadLabel
      }}</label>
    </div>
    <fa-icon
      class="col-auto"
      *ngIf="waitingStaal"
      [icon]="clock"
      style="color: orange"
    ></fa-icon>
    <fa-icon
      class="col-auto"
      *ngIf="finishedStaal"
      [icon]="check"
      style="color: green"
    ></fa-icon>
  </div>
  <div class="row">
    <div class="custom-file col mx-4 py-2">
      <input
        type="file"
        class="custom-file-input"
        id="customFile"
        (change)="onPhotoChange($event.target.files)"
      />
      <label class="custom-file-label" for="customFile">{{
        fotoUploadLabel
      }}</label>
    </div>
    <fa-icon
      class="col-auto"
      *ngIf="waitingFoto"
      [icon]="clock"
      style="color: orange"
    ></fa-icon>
    <fa-icon
      class="col-auto"
      *ngIf="finsihedFoto"
      [icon]="check"
      style="color: green"
    ></fa-icon>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
  <button
    class="btn btn-success"
    (click)="onSave()"
    [disabled]="!buttonEnabled"
  >
    Save
  </button>
</div>
