import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-textiel',
  templateUrl: './textiel.component.html',
  styleUrls: ['./textiel.component.scss']
})
export class TextielComponent implements OnInit {
  public baseImageUrl = 'assets/images/textiel/';
  public images = ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "8.jpg",
    "9.jpg", "10.jpg", "11.jpg", "12.png", "13.jpg", "14.jpg", "15.jpg", "16.jpg", "17.jpg"];

  public modalRef: BsModalRef;
  public activeImage: number = 0;
  @ViewChild('lgModal') templateRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }


}
