<nav class="navbar navbar-light navbar-expand-lg bg-white" >
  <a class="navbar-brand" routerLink="/">
    <img src="assets/kleinlogo.png" alt="home" style="width: 60px;" />
    NAALD & HAMER
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    (click)="isCollapsed = !isCollapsed"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse"
    id="navbarSupportedContent"
    [collapse]="isCollapsed"
  >
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/wiewijzijn">OVER ONS</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/projecten">PROJECTEN</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/bruiloft">BRUILOFTEN</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/naaktstaal">NAAKTSTAAL</a>
      </li>
      <li class="nav-item">
          <a class="nav-link" routerLink="/dieren">STAALDIEREN</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/geometrisch">GEOMETRISCH</a>
        </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/koffiebar">{{
          "Koffiebar" | uppercase
        }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/theater">THEATER</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/contact">CONTACT</a>
      </li>
    </ul>
  </div>
</nav>
