<h1 class="text-center mb-4">
  THEATER
</h1>
<h4 class="text-center">Cinemusic</h4>
<h5 class="text-center">Decor van studio HB staging</h5>
<app-gallery-single
  [imageLocations]="filmrollen"
  imageBaseUrl="/assets/images/filmrollen/"
></app-gallery-single>
<h4 class="text-center">Bovenkamers</h4>
<h5 class="text-center">Decor van studio HB staging</h5>
<app-gallery-single
  [imageLocations]="cadillac"
  imageBaseUrl="/assets/images/cadillac/"
></app-gallery-single>
<h4 class="text-center">Katharina Katharina in het Ganzenbord - Theatergroep Groen Pluche</h4>
<app-gallery-single
  [imageLocations]="katharina"
  imageBaseUrl="/assets/images/katharina/"
></app-gallery-single>
<h4 class="text-center mt-3">Buku Buku De verhalen van Anansi</h4>
<h5 class="text-center">Theaterstraat</h5>
<app-gallery-single
  [imageLocations]="anansi"
  imageBaseUrl="/assets/images/anansi/"
></app-gallery-single>
<h4 class="text-center mt-3">Buku Buku Piñata</h4>
<h5 class="text-center">Theaterstraat</h5>
<p class="text-center"><small>Foto's: Bart Grietens</small></p>
<app-gallery-single
  [imageLocations]="pinata"
  imageBaseUrl="/assets/images/pinata/"
></app-gallery-single>
<h4 class="text-center mt-3">Rake Klappen - Klauterstout</h4>
<div class="row justify-content-center">
  <div class="col-auto">
  <div class="embed-responsive embed-responsive-16by9">
  <iframe
    class="embed-responsive-item"
    src="https://www.youtube.com/embed/dHJFEXm07Qs"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  </div>
</div>
