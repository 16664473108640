import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VerkoopItem } from '../../../models/verkoop-item.model'

@Component({
  selector: 'app-verkoop-item',
  templateUrl: './verkoop-item.component.html',
  styleUrls: ['./verkoop-item.component.scss']
})
export class VerkoopItemComponent implements OnInit {

  @Input() item: VerkoopItem;
  @ViewChild('lgModal') templateRef;
  public modalRef: BsModalRef;
  public images: string[] = [];
  public activeImage : number = 0;
  public selected = 0;
  public showPrice = true;
  private $destroy : Subject<void> = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    const numbers = interval(3000);
    numbers.pipe(takeUntil(this.$destroy)).subscribe(_ => this.showPrice = !this.showPrice );
  }

  openCarroussel(index: number) {
    if (window.innerWidth > 800){
      this.modalRef = this.templateRef.show();
      this.activeImage = index;
    }
  }

  ngOnDestroy(){
    this.$destroy.next();
    this.$destroy.complete();
  }
}
