import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SortableModule } from 'ngx-bootstrap/sortable';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './main/nav/nav.component';
import { MainComponent } from './main/main.component';
import { SaleItemComponent } from './main/sale/sale-item/sale-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './main/home/home.component';
import { KoffiebarComponent } from './main/koffiebar/koffiebar.component';
import { GalleryComponent } from './main/gallery/gallery.component';
import { WiewijzijnComponent } from './main/wiewijzijn/wiewijzijn.component';
import { TheaterComponent } from './main/theater/theater.component';
import { ContactComponent } from './main/contact/contact.component';
import { GallerySingleComponent } from './main/gallery-single/gallery-single.component';
import { TextielComponent } from './main/textiel/textiel.component';
import { OpverzoekComponent } from './main/opverzoek/opverzoek.component';
import { DierenComponent } from './main/sale/dieren/dieren.component';
import { NaaktComponent } from './main/sale/naakt/naakt.component';
import { DiversComponent } from './main/sale/divers/divers.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditDierComponent } from './main/sale/dieren/edit-dieren/edit-dier/edit-dier.component';
import { EditDierenComponent } from './main/sale/dieren/edit-dieren/edit-dieren.component';
import { LoginComponent } from './main/login/login.component';
import { EditNaaktComponent } from './main/sale/naakt/edit-naakt/edit-naakt.component';
import { EditSaleItemComponent } from './main/sale/edit-sale-item/edit-sale-item.component';
import { VerkoopItemComponent } from './main/sale/verkoop-item/verkoop-item.component';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { EditDiversComponent } from './main/sale/divers/edit-divers/edit-divers.component';
import { ExpositieComponent } from './main/expositie/expositie.component';
import { BruiloftComponent } from './main/bruiloft/bruiloft.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ProjectenComponent } from './main/projecten/projecten.component';
import { HornbachComponent } from './main/projecten/hornbach/hornbach.component';
import { ArtisComponent } from './main/projecten/artis/artis.component';

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        NavComponent,
        SaleItemComponent,
        HomeComponent,
        KoffiebarComponent,
        GalleryComponent,
        WiewijzijnComponent,
        TheaterComponent,
        ContactComponent,
        GallerySingleComponent,
        TextielComponent,
        OpverzoekComponent,
        DierenComponent,
        NaaktComponent,
        DiversComponent,
        EditDierenComponent,
        EditDierComponent,
        EditNaaktComponent,
        EditSaleItemComponent,
        VerkoopItemComponent,
        EditDiversComponent,
        LoginComponent,
        ExpositieComponent,
        BruiloftComponent,
        ProjectenComponent,
        HornbachComponent,
        ArtisComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        FormsModule,
        DragDropModule,
        AngularFirestoreModule,
        SortableModule.forRoot(),
        CarouselModule.forRoot(),
        ModalModule.forRoot(),
        CollapseModule.forRoot(),
        FontAwesomeModule,
        ReactiveFormsModule,
        AppRoutingModule,
        ButtonsModule.forRoot()
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
