<div class="container">
  <h1 class="text-center mb-4">
    NAALD & HAMER'S KOFFIEBAR
  </h1>
  <div class="row mb-3 align-items-center">
    <div class="col-md-6 col-12">
      <img class="w-100" src="/assets/images/koffiebar/koffiebarnaaldenhamer.jpg" />
    </div>
    <div class="col-md-6 col-12">
      <p>
        In deze bar komen alle kwaliteiten van Naald & Hamer ultiem samen. Alles
        wat je ziet is zelf gemaakt. Van de constructie tot de luifel tot de
        espressomachines. Wij komen graag koffie zetten op jouw feestje. De bar is
        ook te huur zonder koffie in verschillende formaten en opstellingen.
      </p>
    </div>
  </div>
  <app-gallery [imageLocations]="images" [imageBaseUrl]="imageBaseUrl"></app-gallery>
</div>
