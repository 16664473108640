import { Component } from '@angular/core';

@Component({
  selector: 'app-projecten',
  templateUrl: './projecten.component.html',
  styleUrls: ['./projecten.component.scss']
})
export class ProjectenComponent {

}
