<div class="container">
  <h1 class="text-center mb-4">
    BRUILOFTEN
  </h1>
  <p class="text-center mb-4 mx-6">
    Hier een impressie van wat wij kunnen maken en verhuren voor bruiloften. We doen ook aan verzoekjes :) Mail ons
    voor meer info.
  </p>
  <app-gallery [imageLocations]="images" [imageBaseUrl]="imageBaseUrl"></app-gallery>

</div>
