<div class="modal-header">Voeg Verkoopitem toe</div>
<div class="modal-body">
  <div class="form-group">
    <input
      type="text"
      class="form-control"
      id="exampleFormControlInput1"
      placeholder="naam"
      [formControl]="title"
    />
  </div>
  <div class="form-group">
    <input
      type="text"
      class="form-control"
      placeholder="prijs"
      [formControl]="price"
    />
  </div>
  <div class="form-group">
    <input
      type="text"
      class="form-control"
      placeholder="afmetingen"
      [formControl]="size"
    />
  </div>
  <div class="form-group">
    <input
      type="text"
      class="form-control"
      placeholder="omschrijving voor google"
      [formControl]="alttext"
    />
  </div>

  <div class="form-group">
    <label>Selecteer formaat</label>
    <select class="form-control" id="" [formControl]="format">
      <option>vierkant</option>
      <option>rechthoek</option>
      <option>breedbeeld</option>
    </select>
  </div>
  <div
    *ngFor="let item of imageRefs; let i = index"
    class="row"
  >
  <div class="col py-2 mx-2">
    <input
      type="file"
      class="custom-file-input"
      id="customFile"
      required
      (change)="onStaalChange($event.target.files, i)"
    />
    <label class="custom-file-label" for="customFile">{{
      item.ref ? item.ref : "Photo" + (i + 1)
    }}</label>
  </div>
    <fa-icon
      class="col-auto"
      *ngIf="item.waiting"
      [icon]="clock"
      style="color: orange"
    ></fa-icon>
    <fa-icon
      class="col-auto"
      *ngIf="item.finished"
      [icon]="check"
      style="color: green"
    ></fa-icon>
    <button class="btn btn-danger col-auto mr-2" *ngIf="item.ref && i != 0" (click)="removeImage(item.ref, i)">
      <fa-icon [icon]="check" style="color: white"> </fa-icon>
    </button>
  </div>
  <button class="btn btn-primary" (click)="onNewImageForm()">
    Voeg nog een foto toe
  </button>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
  <button
    class="btn btn-success"
    (click)="onSave()"
    [disabled]="!buttonEnabled"
  >
    Save
  </button>
</div>
