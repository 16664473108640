import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VerkoopItem } from '../../../models/verkoop-item.model';

@Component({
  selector: 'app-naakt',
  templateUrl: './naakt.component.html',
  styleUrls: ['./naakt.component.scss']
})
export class NaaktComponent implements OnInit, OnDestroy
 {
  public verkoopItems : VerkoopItem[];
  private closeNotifier$: Subject<void> = new Subject();

  constructor(private fireStore: AngularFirestore) {
    this.fireStore.collection<VerkoopItem>('naaktstaal').valueChanges().pipe(takeUntil(this.closeNotifier$)).subscribe(
      data => this.verkoopItems = data.sort((a, b) => b.position - a.position));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.closeNotifier$.next();
    this.closeNotifier$.complete();
  }


}
