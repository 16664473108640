<div class="gallery">
  <div *ngFor="let item of imageLocations; let i = index" class="gallery-image">
    <img
      class="pr-1 pt-1 row-image"
      src="{{ imageBaseUrl + item }}"
      (click)="openCarroussel(i)"
    />
  </div>
  <div class="dummy"></div>
</div>
<!--
<button
  type="button"
  class="close pull-right"
  aria-label="Close"
  (click)="modalRef.hide()"
>
  <span aria-hidden="true">&times;</span>
</button> -->
<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="lgModal?.hide()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <carousel interval="0" class="my-gallery" [activeSlide]="activeImage" [showIndicators]="false">
          <slide *ngFor="let im of imageLocations">
            <img
              src="{{ imageBaseUrl + im }}"
              style="display: block; max-width: 100% ; min-width: 500px; max-height:100%;"
            />
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</div>
