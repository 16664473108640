<div class="container">
  <h1 class="text-center mb-4">NAAKTSTAAL</h1>
  <div class="row justify-content-center">
    <div class="col-lg-8">
      <p>
        Deze collectie ontwikkelt zich doorlopend en we plaatsten hier
        regelmatig nieuwe werken. Zie je iets wat je wilt hebben? Of wil je
        jezelf vereeuwigd zien in staal? Neem dan contact met ons op via
        <a href="mailto:info@naaldenhamer.nl">info@naaldenhamer.nl</a>
      </p>
    </div>
  </div>
  <div class="row justify-content-center">
    <app-verkoop-item
      class="mt-4"
      *ngFor="let item of verkoopItems"
      [class]="item.class? item.class : 'col-lg-3'"
      [item]="item"
    ></app-verkoop-item>
  </div>
</div>
