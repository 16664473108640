import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SaleItem } from 'src/app/models/sale-item.model';
import { VerkoopItem } from 'src/app/models/verkoop-item.model';

@Component({
  selector: 'app-divers',
  templateUrl: './divers.component.html',
  styleUrls: ['./divers.component.scss']
})
export class DiversComponent implements OnInit {


  public verkoopItems : VerkoopItem[];
  private closeNotifier$: Subject<void> = new Subject();

  constructor(private fireStore: AngularFirestore) {
    this.fireStore.collection<VerkoopItem>('divers').valueChanges().pipe(takeUntil(this.closeNotifier$)).subscribe(
      data => this.verkoopItems = data.sort((a, b) => b.position - a.position));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.closeNotifier$.next();
    this.closeNotifier$.complete();
  }


}
