import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-gallery-single',
  templateUrl: './gallery-single.component.html',
  styleUrls: ['./gallery-single.component.scss']
})
export class GallerySingleComponent implements OnInit {

  public modalRef: BsModalRef;
  public activeImage: number = 0;
  @Input() imageLocations: string[];
  @Input() imageBaseUrl: string;
  @ViewChild('lgModal') templateRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  openCarroussel(index: number) {
    if (window.innerWidth > 800){
      this.modalRef = this.templateRef.show();
      this.activeImage = index;
    }
  }
}
