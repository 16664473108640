// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCwU2YFKAaENZKPexDuaGI4hPTt3LRmjH4",
    authDomain: "naaldenhamer-f1cde.firebaseapp.com",
    projectId: "naaldenhamer-f1cde",
    storageBucket: "naaldenhamer-f1cde.appspot.com",
    messagingSenderId: "683483888892",
    appId: "1:683483888892:web:e9a6b1c7e745c1a838eb79"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
