import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Dier, DierId } from 'src/app/models/dier.model';
import { StorageService } from 'src/app/storage-service';
import { faClock, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-edit-dier',
  templateUrl: './edit-dier.component.html',
  styleUrls: ['./edit-dier.component.scss']
})
export class EditDierComponent implements OnInit, OnDestroy {

  public staalUploadLabel = 'Upload een staalfoto'
  public fotoUploadLabel = 'Upload eventueel de originele foto';
  public dier: DierId;
  public buttonEnabled = true;
  public imagePosition: number;
  private staalRef: string;
  private fotoRef: string = '';
  public waitingStaal = false;
  public waitingFoto = false;
  public finishedStaal = false;
  public finsihedFoto = false;
  public clock = faClock;
  public check = faCheckCircle;
  private staalChanged = false;
  private fotoChanged = false;
  private $destroyNotifier: Subject<void> = new Subject();
  name = new UntypedFormControl('');
  alttext = new FormControl('');

  constructor(private storageService: StorageService, public bsModalRef: BsModalRef) { }

  ngOnInit() {
    if (this.dier) {
      this.staalUploadLabel = this.dier.staalRef;
      this.fotoUploadLabel = this.dier.fotoRef;
      this.staalRef = this.dier.staalRef;
      this.fotoRef = this.dier.fotoRef;
      this.name.setValue(this.dier.name);
      this.alttext.setValue(this.dier.alttext);
    }
  }

  onStaalChange(file: File[]) {
    this.waitingStaal = true;
    this.finishedStaal = false;
    this.staalUploadLabel = file[0].name;
    this.staalChanged = true;
    if (this.dier) {
      this.storageService.deletePhoto(this.dier.staalRef);

    }
    this.storageService.uploadFileAndGetMetadata('dieren', file[0]).pipe(takeUntil(this.$destroyNotifier))
      .subscribe(imageRef => {
        this.staalRef = imageRef;
        this.buttonEnabled = true;
        this.waitingStaal = false;
        this.finishedStaal = true;
      });
  }

  onPhotoChange(file: File[]) {
    this.waitingFoto = true;
    this.finsihedFoto = false;
    this.buttonEnabled = false;
    this.fotoChanged = true;
    this.fotoUploadLabel = file[0].name;
    this.storageService.uploadFileAndGetMetadata('dieren', file[0]).pipe(takeUntil(this.$destroyNotifier))
      .subscribe(imageRef => {
        this.fotoRef = imageRef;
        this.buttonEnabled = true;
        this.waitingFoto = false;
        this.finsihedFoto = true;
      });
  }

  onSave() {
    const editDier: Dier = {
      name: this.name.value,
      staalRef: this.staalRef,
      fotoRef: this.fotoRef,
      position: this.imagePosition,
      alttext: this.alttext.value
    }
    if (this.dier && this.dier.id) {
      this.storageService.updateDier(this.dier.id, editDier);
    }
    else {
      this.storageService.UploadDier(editDier);
    }
    this.bsModalRef.hide();
  }

  onCancel() {
    if (this.staalRef && this.staalChanged) {
      this.storageService.deletePhoto(this.staalRef);
    }
    if (this.fotoRef && this.fotoChanged) {
      this.storageService.deletePhoto(this.fotoRef);
    }
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.$destroyNotifier.next();
    this.$destroyNotifier.complete();
  }

}
