import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Dier, DierId } from '../../../../models/dier.model';
import { EditDierComponent } from './edit-dier/edit-dier.component';
@Component({
  selector: 'app-edit-dieren',
  templateUrl: './edit-dieren.component.html',
  styleUrls: ['./edit-dieren.component.scss']
})
export class EditDierenComponent implements OnInit, OnDestroy {

  public dieren: DierId[];
  public modalRef: BsModalRef;
  closeNotifier$: Subject<void> = new Subject();
  constructor(private fireStore: AngularFirestore,
    private modalService: BsModalService,
    private storage: AngularFireStorage
  ) {
    this.fireStore.collection<Dier>('dieren').snapshotChanges().pipe(
      takeUntil(this.closeNotifier$),
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    ).subscribe(result => this.dieren = result.sort((a,b) => b.position - a.position));

  }

  ngOnInit() {
  }

  onEditDier(dier: DierId) {
    const data = {
      imagePosition: dier.position,
      dier: dier,
    }
    this.modalService.show(EditDierComponent, {
      initialState: data,
      backdrop: true,
      ignoreBackdropClick: true
    })
  }

  onNieuwDier() {
    const data = {
      imagePosition: this.dieren ? this.dieren.length : 0
    }

    this.modalRef = this.modalService.show(EditDierComponent, {
      initialState: data,
      backdrop: true,
      ignoreBackdropClick: true
    });
  }

  onDeleteDier(dier: DierId) {
    this.storage.refFromURL(dier.staalRef).delete();
    if (dier.fotoRef) {
      this.storage.refFromURL(dier.fotoRef).delete();
    }
    this.fireStore.collection('dieren').doc(dier.id).delete();
  }

  public saveOrder(): void {
    this.dieren.forEach((dier, index) => {
      if (dier.position != this.dieren.length-index) {
        this.fireStore.collection('dieren').doc(dier.id).update({ position: this.dieren.length - index })
      }
    });

  }

  ngOnDestroy(): void {
    this.closeNotifier$.next();
    this.closeNotifier$.complete();
  }
}
