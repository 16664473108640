import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './main/home/home.component';
import { KoffiebarComponent } from './main/koffiebar/koffiebar.component';
import { WiewijzijnComponent } from './main/wiewijzijn/wiewijzijn.component';
import { TheaterComponent } from './main/theater/theater.component';
import { ContactComponent } from './main/contact/contact.component';
import { TextielComponent } from './main/textiel/textiel.component';
import { OpverzoekComponent } from './main/opverzoek/opverzoek.component';
import { DierenComponent } from './main/sale/dieren/dieren.component';
import { NaaktComponent } from './main/sale/naakt/naakt.component';
import { DiversComponent } from './main/sale/divers/divers.component';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { EditDierenComponent } from './main/sale/dieren/edit-dieren/edit-dieren.component';
import { EditNaaktComponent } from './main/sale/naakt/edit-naakt/edit-naakt.component';
import { LoginComponent } from './main/login/login.component';
import { EditDiversComponent } from './main/sale/divers/edit-divers/edit-divers.component';
import { ExpositieComponent } from './main/expositie/expositie.component';
import { BruiloftComponent } from './main/bruiloft/bruiloft.component';
import { ProjectenComponent } from './main/projecten/projecten.component';
import { HornbachComponent } from './main/projecten/hornbach/hornbach.component';
import { ArtisComponent } from './main/projecten/artis/artis.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'koffiebar',
    component: KoffiebarComponent,
  },
  {
    path: 'wiewijzijn',
    component: WiewijzijnComponent,
  },
  {
    path: 'theater',
    component: TheaterComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'textiel',
    component: TextielComponent,
  },
  {
    path: 'opverzoek',
    component: OpverzoekComponent,
  },
  {
    path: 'dieren',
    component: DierenComponent,
  },
  {
    path: 'naaktstaal',
    component: NaaktComponent,
  },
  {
    path: 'geometrisch',
    component: DiversComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'expositie',
    component: ExpositieComponent,
  },
  {
    path: 'bruiloft',
    component: BruiloftComponent,
  },
  {
    path: 'edit',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: 'dieren',
        component: EditDierenComponent,
      },
      {
        path: 'naakt',
        component: EditNaaktComponent,
      },
      {
        path: 'divers',
        component: EditDiversComponent,
      },
    ],
  },
  {
    path: 'projecten',
    component: ProjectenComponent,
  },
  { path: 'projecten/hornbach', component: HornbachComponent },
  { path: 'projecten/artis', component: ArtisComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
