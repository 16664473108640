import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BsModalService } from 'ngx-bootstrap/modal';
import { VerkoopItemId, VerkoopItem } from 'src/app/models/verkoop-item.model';
import { EditSaleItemComponent } from '../../edit-sale-item/edit-sale-item.component';
import { map, takeUntil } from 'rxjs/operators';
import { StorageService } from 'src/app/storage-service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-naakt',
  templateUrl: './edit-naakt.component.html',
  styleUrls: ['./edit-naakt.component.scss']
})
export class EditNaaktComponent implements OnInit, OnDestroy {
  public verkoopItems : VerkoopItemId[];
  closeNotifier$: Subject<void> = new Subject();

  constructor(private modalService: BsModalService,
        private fireStore: AngularFirestore,
        private storageService : StorageService
    ) { }

  ngOnInit() {
    this.fireStore.collection<VerkoopItem>('naaktstaal').snapshotChanges().pipe(
      takeUntil(this.closeNotifier$),
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    ).subscribe(result => {this.verkoopItems = result.sort((a, b) => b.position - a.position)});
  }

  onDeleteNaakt(item:VerkoopItemId){
    console.log(item.imageRefs);
    item.imageRefs.forEach(element => {
      this.storageService.deletePhoto(element);
    });
    this.fireStore.collection('naaktstaal').doc(item.id).delete();
  }

  onEditNaakt(item:VerkoopItemId){
    const data = {
      imagePosition: this.verkoopItems?.length > 0 ? this.verkoopItems.length : 0,
      verkoopItem : item,
      collection: 'naaktstaal'
    }

    this.modalService.show(EditSaleItemComponent, {
      initialState: data,
      backdrop: true,
      ignoreBackdropClick: true
    });

  }

  public saveOrder(): void {
    this.verkoopItems.forEach((item, index) => {
      if (item.position != this.verkoopItems.length-index) {
        this.fireStore.collection('naaktstaal').doc(item.id).update({ position: this.verkoopItems.length - index })
      }
    });
  }

  onNieuwNaakt() {
    const data = {
      imagePosition: this.verkoopItems?.length > 0 ? this.verkoopItems.length : 0,
      collection: 'naaktstaal'
    }

    this.modalService.show(EditSaleItemComponent, {
      initialState: data,
      backdrop: true,
      ignoreBackdropClick: true
    });
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.verkoopItems, event.previousIndex, event.currentIndex);
  }

  ngOnDestroy(): void {
    this.closeNotifier$.next();
    this.closeNotifier$.complete();
  }
}
