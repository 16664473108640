import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theater',
  templateUrl: './theater.component.html',
  styleUrls: ['./theater.component.scss']
})
export class TheaterComponent implements OnInit {

  katharina = [
    "1.jpg",
    "2.jpg",
    "3.jpg",
    "4.jpg",
    "5.jpg"
  ]

  cadillac = [
    "1.jpg"
  ]

  filmrollen = [
    "1.jpg",
    "2.jpg"
  ]

  anansi = [
    "1.jpg",
    "2.jpg",
    "3.jpg",
    "4.jpg"
  ]

  pinata =[
    "1.jpg",
    "2.jpg"
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
