import { Component, OnInit } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public envelopeIcon = faEnvelope;
  public instagram = faInstagram;
  public faceBook = faFacebook;

  constructor() { }

  ngOnInit(): void {
  }

}
