import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public authenticatedUser : boolean = false;
  public username: UntypedFormControl = new UntypedFormControl('');
  public password: UntypedFormControl = new UntypedFormControl('');
  private closeNotifier$: Subject<void> = new Subject();
  constructor(private fireAuth : AngularFireAuth) { }

  ngOnInit() {
    this.fireAuth.user.pipe(takeUntil(this.closeNotifier$)).subscribe(user => this.authenticatedUser = user != null)
  }

  public login(){
    this.signin(this.username.value, this.password.value);
  }

  private signin(email: string, password: string) {
    this.fireAuth.signInWithEmailAndPassword(email, password)
      .then(() => this.authenticatedUser = true)
      .catch((reason) => this.authenticatedUser = false)
  }

  public signOut() {
    this.fireAuth.signOut();
    this.authenticatedUser = false;
  }

  ngOnDestroy(): void {
    this.closeNotifier$.next();
    this.closeNotifier$.complete();
  }
}


