import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BsModalRef } from 'ngx-bootstrap/modal/public_api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Dier } from 'src/app/models/dier.model';

@Component({
  selector: 'app-dieren',
  templateUrl: './dieren.component.html',
  styleUrls: ['./dieren.component.scss']
})
export class DierenComponent implements OnInit, OnDestroy {


  @ViewChild('lgModal') templateRef;
  public dieren: Dier[];
  public modalRef: BsModalRef;
  public activeImage: number = 0;
  public selected = 0;
  private closeNotifier$: Subject<void> = new Subject();


  constructor(private fireStore: AngularFirestore) {
    this.fireStore.collection<Dier>('dieren').valueChanges().pipe(takeUntil(this.closeNotifier$)).subscribe(
      data => this.dieren = data.sort((a, b) => b.position - a.position));
  }

  ngOnInit(): void {
  }

  openCarroussel(index: number) {
    if (window.innerWidth > 800) {
      this.modalRef = this.templateRef.show();
      this.activeImage = index;
    }
  }

  ngOnDestroy(): void {
    this.closeNotifier$.next();
    this.closeNotifier$.complete();
  }
}
