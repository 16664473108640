<div class="container">
  <h1 class="text-center mb-4">WIE WIJ ZIJN EN WAT WE MAKEN</h1>
  <div class="row">
    <div class="col-md-5 col-12">
      <img class="w-100" src="/assets/images/overons.jpg" />
    </div>
    <div class="col-md-7 col-12">
      <p>
        Wij van Naald & Hamer zijn vrouwen van staal. We laten ons het liefst
        leiden door fascinaties voor vorm en materiaal. Ons atelier is bij
        vrijplaats de <a [href]="'http://vliegtuin.nl'" target="_blank">Vliegtuin</a>, een plek met volop ruimte om te experimenteren.
        Daar zijn we aan het lassen, slijpen, timmeren, sjouwen en bouwen en
        creëren we bijzondere objecten.
      </p>

      <p>
        We hebben bij de Vliegtuin leren lassen door een project waarbij de oude
        espressomachines hebben omgebouwd tot koffiemakende lichtkunstwerken.
        Het lassen beviel zo goed, dat we nu voornamelijk met staal werken.
      </p>

      <p>
        We houden zo van staal omdat het bijna elke vorm kan aannemen die we
        wensen. Daarom werken we ook het liefst met oud ijzer, want staal valt
        bijna eindeloos te transformeren. We slijpen, buigen en smelten tot
        hoekige en ronde vormen. We werken doorlopend aan onze eigen
        kunstwerken, zoals de collecties Naaktstaal en Geometrisch. Daarnaast
        werken we graag in opdracht, klein en groot. Zo maken we bijvoorbeeld
        unieke hekwerken, stalen portretten van huisdieren, kandelaren,
        decorstukken, bruiloftsdecoratie en hebben we voor de speeltuin in Artis
        een bogenportaal gemaakt.
      </p>

      <p>
        Dus ben je opzoek naar iets bijzonders of persoonlijks, een cadeau voor
        jezelf of geliefde of vind je je tuinhekje veel te saai? Wij maken graag
        iets passends voor je. Uniek en handgemaakt. Neem contact met ons op via
        <a href="mailto:info@naaldenhamer.nl">info@naaldenhamer.nl</a> en dan reageren we zo snel mogelijk :)
      </p>
    </div>
  </div>
  <div class="mt-3">
    <app-gallery
      [imageLocations]="images"
      [imageBaseUrl]="baseImageUrl"
    ></app-gallery>
  </div>
</div>
