<div>
  Hoi Naald en Hamer, hier kunnen jullie je staaldieren toevoegen. Ze moeten vierkant zijn,want dat is mooi. Als het even kan niet groter van 800KB.
  Anders wordt de website traag en verbruiken jullie heel veel data, terwijl dat niet nodig is. Je hoeft niet perse een originele foto toe te voegen
  De staalfoto is wel verplicht. Naam maakt niet heel veel uit, maar is wel fijn om eventueel wat terug te zoeken.
  De foto die je toevoegt komt (meestal) automatisch vooraan te staan. Maar mocht je dat niet willen, kun je de foto's slepen naar de goede positie.
  Druk dan wel op de save button. Als je een dier wil deleten dan verwijder je ook de foto echt van de server. Zelfde geldt voor als je een edit doet en de foto aanpast, dus zorg dat
  je ze zelf ook nog ergens hebt. Af en toe is het uploaden traag. Als ie oranje klokje heeft moet je even geduld hebben, en je kunt pas saven als ie groen is.
</div>
<button type="button" class="btn btn-primary" (click)="onNieuwDier()">
  Voeg nieuw dier toe
</button>
<button type="button" class="btn btn-success" (click)="saveOrder()">Sla volgorde op</button>
<div class="row justify-content-center">
  <bs-sortable
    [(ngModel)]="dieren"
    [itemTemplate]="itemTemplate"
    itemClass="sortable-item col-md-3 col-12 mt-3"
    itemActiveClass="sortable-item-active"
    placeholderItem="Drag here"
    placeholderClass="placeholderStyle"
    wrapperClass="sortable-wrapper"
  ></bs-sortable>
  <ng-template #itemTemplate let-item="item" let-index="index">
    <div class="image-box">
      <img [src]="item.value.staalRef" class="w-100" />
      <img
        class="hover-img w-100"
        *ngIf="item.value.fotoRef"
        [src]="item.value.fotoRef"
      />
      <button class="btn btn-primary" (click)="onEditDier(item.value)">
        edit
      </button>
      <button class="btn btn-danger" (click)="onDeleteDier(item.value)">
        delete
      </button>
    </div>
  </ng-template>
</div>
