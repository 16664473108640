import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, UntypedFormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StorageService } from 'src/app/storage-service';
import { faClock, faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VerkoopItem, VerkoopItemId } from 'src/app/models/verkoop-item.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-edit-sale-item',
  templateUrl: './edit-sale-item.component.html',
  styleUrls: ['./edit-sale-item.component.scss']
})

export class EditSaleItemComponent implements OnInit {

  public verkoopItem: VerkoopItemId;
  public collection: string;
  public buttonEnabled = false;
  public imagePosition: number;
  public imageRefs: uploadImage[] = [
    {
      waiting: false,
      ref: '',
      finished: false,
      changedImage: false
    }
  ]
  public clock = faClock;
  public check = faCheckCircle;
  public cancel = faTimesCircle;
  private $destroyNotifier: Subject<void> = new Subject();
  public title = new UntypedFormControl('');
  public price = new UntypedFormControl('');
  public size = new UntypedFormControl('');
  public format = new UntypedFormControl('');
  public alttext = new FormControl('');

  constructor(private storageService: StorageService,
    private fireStore: AngularFirestore,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnDestroy(): void {
    this.$destroyNotifier.next();
    this.$destroyNotifier.complete();
  }

  ngOnInit() {
    if (this.verkoopItem && this.verkoopItem.id) {
      this.title.setValue(this.verkoopItem.title);
      this.size.setValue(this.verkoopItem.size);
      this.format.setValue(this.verkoopItem.imageFormat);
      this.price.setValue(this.verkoopItem.price)
      this.imageRefs = this.verkoopItem.imageRefs.map(i => { return { ref: i, waiting: false, changedImage: true, finished: true } });
      this.alttext.setValue(this.verkoopItem.altText);
      this.buttonEnabled = true;
    }
  }

  onNewImageForm() {
    this.imageRefs.push({
      waiting: false,
      ref: '',
      finished: false,
      changedImage: false
    })
  }

  onStaalChange(file: File[], index: number) {
    this.buttonEnabled = false;
    this.imageRefs[index].waiting = true;
    this.imageRefs[index].finished = false;
    this.imageRefs[index].changedImage = true;
    if (this.imageRefs[index].ref) {
      this.storageService.deletePhoto(this.imageRefs[index].ref);
    }
    this.storageService.uploadFileAndGetMetadata(this.collection, file[0]).pipe(takeUntil(this.$destroyNotifier))
      .subscribe(imageRef => {
        this.imageRefs[index].ref = imageRef;
        this.buttonEnabled = true;
        this.imageRefs[index].waiting = false;
        this.imageRefs[index].finished = true;
      });
  }

  onSave() {
    const editItem: VerkoopItem = {
      title: this.title.value,
      price: this.price.value,
      imageFormat: this.format.value !== 'breedbeeld' ? this.format.value : 'rechthoek',
      size: this.size.value,
      position: this.imagePosition,
      imageRefs: this.imageRefs.map(x => x.ref),
      class: this.format.value == 'breedbeeld' ? 'col-lg-6' : 'col-lg-3',
      altText: this.alttext.value
    }
    if (this.verkoopItem && this.verkoopItem.id) {
      this.fireStore.collection(this.collection).doc(this.verkoopItem.id).update(editItem)
    }
    else {
      this.fireStore.collection(this.collection).add(editItem);
    }
    this.bsModalRef.hide();
  }

  onCancel() {

    this.bsModalRef.hide();
  }

  removeImage(ref: string, index: number) {
    this.storageService.deletePhoto(ref);
    this.imageRefs.splice(index, 1);
  }
}

export interface uploadImage {
  ref: string;
  waiting: boolean;
  finished: boolean;
  changedImage: boolean;
}
