<div class="row w-75 homepage justify-content-center">
  <div class="row">
    <div class="image-container col-lg-3 col-12 mt-4">
      <a routerLink="/wiewijzijn">
        <div class="d-flex">
          <img class="w-100" src="assets\images\home\WieWijZijn.jpg" />
          <div class="overlay">
            <div class="text">OVER ONS</div>
          </div>
        </div>
        <div class="d-flex hidden-regular">
          <div class="tile-banner">
            <p class="text-center">OVER ONS</p>
          </div>
        </div>
      </a>
    </div>

    <div class="image-container col-lg-3 col-12 mt-4">
      <a routerLink="/projecten">
        <div class="d-flex">
          <img class="w-100" src="assets/images/projecten/bbq4.jpg" />
          <div class="overlay">
            <div class="text">PROJECTEN</div>
          </div>
        </div>
        <span class="hidden-regular">
          <div class="tile-banner"><p class="text-center">PROJECTEN</p></div>
        </span>
      </a>
    </div>


    <div class="image-container col-lg-3 col-12 mt-4">
        <a routerLink="/bruiloft">
          <div class="d-flex">
            <img class="w-100" src="assets\images\bruiloft/hartenvuur.gif" />
            <div class="overlay">
              <div class="text">BRUILOFTEN</div>
            </div>
          </div>
          <span class="hidden-regular">
            <div class="tile-banner">
              <p class="text-center">BRUILOFTEN</p>
            </div>
          </span>
        </a>
      </div>

    <div class="image-container col-lg-3 col-12 mt-4">
      <a routerLink="/naaktstaal">
        <div class="d-flex">
          <img class="w-100" src="assets\images\home/naaktstaal.jpg" />
          <div class="overlay">
            <div class="text">NAAKTSTAAL</div>
          </div>
        </div>
        <span class="hidden-regular">
          <div class="tile-banner">
            <p class="text-center">NAAKTSTAAL</p>
          </div>
        </span>
      </a>
    </div>



    <div class="image-container col-lg-3 col-12 mt-4">
      <a routerLink="/dieren">
        <div class="d-flex">
          <img class="w-100" src="assets\images\home/poes.jpg" />
          <div class="overlay">
            <div class="text">STAALDIEREN</div>
          </div>
        </div>
        <span class="hidden-regular">
          <div class="tile-banner">
            <p class="text-center">STAALDIEREN</p>
          </div>
        </span>
      </a>
    </div>

    <div class="image-container col-lg-3 col-12 mt-4">
      <a routerLink="/geometrisch">
        <div class="d-flex">
          <img class="w-100" src="assets\images\home/geometrisch.jpg" />
          <div class="overlay">
            <div class="text">GEOMETRISCH</div>
          </div>
        </div>
        <span class="hidden-regular">
          <div class="tile-banner">
            <p class="text-center">GEOMETRISCH</p>
          </div>
        </span>
      </a>
    </div>

    <div class="image-container col-lg-3 col-12 mt-4">
      <a routerLink="/koffiebar">
        <div class="d-flex">
          <img class="w-100" src="assets\images\home/Koffiebar.jpg" />
          <div class="overlay">
            <div class="text">KOFFIEBAR</div>
          </div>
        </div>
        <span class="hidden-regular">
          <div class="tile-banner"><p class="text-center">KOFFIEBAR</p></div>
        </span>
      </a>
    </div>



    <div class="image-container col-lg-3 col-12 mt-4">
      <a routerLink="/theater">
        <div class="d-flex">
          <img class="w-100" src="assets/images/home/Theater.jpg" />
          <div class="overlay">
            <div class="text">THEATER</div>
          </div>
        </div>
        <span class="hidden-regular">
          <div class="tile-banner"><p class="text-center">THEATER</p></div>
        </span>
      </a>
    </div>


  </div>
</div>
