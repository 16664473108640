import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-bruiloft',
    templateUrl: './bruiloft.component.html',
    styleUrls: ['./bruiloft.component.scss']
})
export class BruiloftComponent implements OnInit {
    public imageBaseUrl = 'assets/images/bruiloft/';
    public images = [
        'hartenvuur.gif',
        'boog.jpg',
        'harten1.jpg',
        'harten2.jpg',
        'harten3.jpg',
        'harten4.jpg',
        'harten5.jpg',
        'dj2.jpg',
        'outline.jpg',
        'bruiloftportret.jpg'
    ];

    constructor() { }

    ngOnInit() {
    }

}
