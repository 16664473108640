import { Component } from '@angular/core';

@Component({
  selector: 'app-artis',
  templateUrl: './artis.component.html',
  styleUrls: ['./artis.component.scss']
})
export class ArtisComponent {

}
