<div class="gallery">
  <!-- <div class="gallery-image"> -->
    <img *ngFor="let item of imageLocations; let i = index"
      class="pr-1 pt-1 row-image"
      src="{{ imageBaseUrl + item }}"
      (click)="openCarroussel(i)"
    />
  <!-- </div> -->
</div>

<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title pull-left">
        <button type="button" class="close" (click)="lgModal.hide()">
          <span aria-hidden="true" style="font-size: 30px;">&times;</span>
        </button>
      </div>
      </div>
      <div class="modal-body">
        <carousel interval="0" class="my-gallery" [activeSlide]="activeImage" [showIndicators]="false">
          <slide *ngFor="let im of imageLocations">
            <img
              src="{{ imageBaseUrl + im }}"
              style="display: block; max-width: 100% ; min-width: 500px; max-height:100%;"
            />
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</div>
