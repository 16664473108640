<div class="container">
  <h1 class="text-center mb-4">GEOMETRISH</h1>
  <p>Hoi Naaldenhamer,</p>

  <p>
    Hier kunnen jullie naakstaal foto's toevoegen en editen. Geen van de velden
    zijn verplicht, behalve de foto en het formaat. Vergeet in het prijs veld
    het euro teken niet, dat doet ie niet vanzelf. Paar dingen om op te letten:
  </p>
  <ul>
    <li>
      Om een foto toe te voegen klik je op browse, je krijgt dan een popup waar
      je foto's kunt selecteren. Hou ze een beetje klein, onder de 1 MB, maar
      liever van rond de 800kb Wacht geduldig tot het oranje klokje een groen
      vinkje wordt. Soms is dit 1 seconden, soms 20
    </li>
    <li>
      Als je op cancel drukt verwijdert ie alle foto's die je tot dan toe hebt
      geupload, dus ook als je wil editen. Dus edit en op cance drukken is niet
      zo fijn. Ik ga nadenken hoe ik dit beter kan doen.
    </li>
    <li>
      Je hebt 3 opties voor formaten, vierkant, rechthoek (rechtop), en
      breedbeeld. Breedbeeld In dit geval hoeft het formaat niet te matchen met
      de vorm van de foto. Hij knipt ze zelf goed. Veel foto's kunnen
      bijvoorbeeld als rechthoek en vierkant. Het is wel aan te raden ze per 4
      te bundelen zodat ze allemaal even hoog zijn in een rij.
    </li>
    <li>
      ALs je een foto toevoegt komt ie meestal als eerste te staan, en staat ie
      ook meteen online. Je kunt daarna de volgorde aanpassen met drag en drop.
      En je ziet dus hoe ie er ook op de echte pagina uitziet.
    </li>
    <li>
      Editen is makkelijk, druk op de edit knop onder de foto. Je mag alles achteraf nog aanpasssen.
    </li>
  </ul>

  <div class="row my-4">
    <button type="button" class="btn btn-primary" (click)="onNieuwItem()">
      Voeg nieuw item toe
    </button>
    <button type="button" class="btn btn-success" (click)="saveOrder()">
      Sla volgorde op
    </button>
  </div>
  <div
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    class="row justify-content-center"
  >
    <div
      *ngFor="let item of verkoopItems"
      [class]="item.class ? item.class : 'col-lg-3'"
    >
      <app-verkoop-item class="mt-4" [item]="item" cdkDrag></app-verkoop-item>

      <button class="btn btn-primary" (click)="onEditItem(item)">edit</button>
      <button class="btn btn-danger" (click)="onDeleteItem(item)">
        delete
      </button>
    </div>
  </div>
</div>
