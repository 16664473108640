import { Injectable } from "@angular/core";
import { AngularFirestore } from'@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';

import { from, Observable } from "rxjs";
import { switchMap } from 'rxjs/operators';
import { Dier } from "./models/dier.model";

@Injectable({ providedIn: 'root' })

export class StorageService {
  constructor(private readonly storage: AngularFireStorage,
    private readonly db : AngularFirestore) {

  }

  public uploadFileAndGetMetadata(
    mediaFolderPath: string,
    fileToUpload: File
  ): Observable<string> {
    const { name } = fileToUpload;
    const filePath = `${mediaFolderPath}/${new Date().getTime()}_${name}`;
    const uploadTask: AngularFireUploadTask = this.storage.upload(
      filePath,
      fileToUpload,
    );
    return this.getDownloadUrl$(uploadTask, filePath);
  }

  public UploadDier(dier : Dier){
    this.db.collection('dieren').add(dier)
  }

  public updateDier(id:string, dier: Dier){
    this.db.collection('dieren').doc(id).update(dier);
  }

  public deletePhoto(ref: string){
    this.storage.refFromURL(ref).delete();
  }

  private getDownloadUrl$(
    uploadTask: AngularFireUploadTask,
    path: string,
  ): Observable<any> {
    return from(uploadTask).pipe(
      switchMap((_) => this.storage.ref(path).getDownloadURL()),
    );
  }


}
