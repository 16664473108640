<div class="container-fluid w-75">
  <h1 class="text-center mb-4">
    OP VERZOEK GEMAAKT
  </h1>
  <div class="row align-items-center">
    <div class="col-md-6 col-12">
      <img class="w-100" src="/assets/images/opverzoek/vlaggetjesbosjes.jpg" />
    </div>
    <img class="col-lg-3 col-12" src="/assets/images/opverzoek/hekjevoor.jpg" />
    <img class="col-lg-3 col-12" src="/assets/images/opverzoek/hekjena.jpg" />
  </div>
  <div class="mt-3">
    <app-gallery [imageLocations]="images" [imageBaseUrl]="baseImageUrl"></app-gallery>
  </div>
</div>
