<div class="row justify-content-center">
  <div class="col imagecarousel">
    <carousel interval="0" [showIndicators]="false">
      <slide *ngFor="let im of images; let i = index">
        <img
          [src]="im"
          style="display: block; width: 100%"
          [class]="item.imageFormat"
          (click)="openCarroussel(i)"
        />
        <div class="overlay d-flex">
          <span>{{ item.title }}</span
          ><span class="spacer"></span>{{ showPrice || !item.size ?  item.price : item.size }}
        </div>
      </slide>
    </carousel>
  </div>
</div>

<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="lgModal.hide()">
          <span aria-hidden="true" style="font-size: 30px">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <carousel
          interval="0"
          class="my-gallery"
          [activeSlide]="activeImage"
          [showIndicators]="false"
        >
          <slide *ngFor="let im of images">
            <img
              src="{{ im }}"
              style="
                display: block;
                max-width: 100%;
                min-width: 500px;
                max-height: 100%;
              "
            />
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</div>
