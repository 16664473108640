<div class="container-fluid mx-10">
  <app-nav class="d-flex justify-content-center"></app-nav>
  <router-outlet class="d-flex justify-content-center"></router-outlet>
  <footer class="">
    <div class="row justify-content-center mt-3">
      <div class="col-auto">
        <a href="mailto:info@naaldenhamer.nl">
          <fa-icon class="social" [icon]="envelopeIcon"></fa-icon>
        </a>
      </div>
      <div class="col-auto">
        <a href="https://www.instagram.com/naald.en.hamer">
          <fa-icon class="social" [icon]="instagram"></fa-icon>
        </a>
      </div>
      <div class="col-auto">
        <a href="https://www.facebook.com/naaldenhamer/">
          <fa-icon class="social" [icon]="faceBook"></fa-icon>
        </a>
      </div>
    </div>
    <hr />
    <p class="footerText">All rights reserved by Naald en Hamer©</p>
  </footer>
</div>
