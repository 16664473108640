<div class="container">
  <div class="row justify-content-center">
    <p class="col-md-8">
      Op verzoek creëren we sculpturen van dieren van
      staal. We gebruiken hiervoor diverse foto's als inspiratie. De prijs is
      afhankelijk van het gewenste formaat en complexiteit. Dus ben je op zoek
      naar een bijzonder of persoonlijk cadeau, voor jezelf of een geliefde?
      Neem contact met ons op via
      <a href="mailto:info@naaldenhamer.nl">info@naaldenhamer.nl</a> :)
    </p>
  </div>
  <div class="row justify-content-center">
    <div
      *ngFor="let dier of dieren; let i = index"
      class="image-box col-md-3 col-12 mt-3"
    >
      <img [src]="dier.staalRef" class="w-100" (click)="openCarroussel(i)" [alt]="dier.alttext"
      />
      <img
        class="hover-img w-100"
        *ngIf="dier.fotoRef"
        [src]="dier.fotoRef"
        [alt]="dier.alttext"
        (click)="openCarroussel(i)"
      />
    </div>
  </div>
  <div
    bsModal
    #lgModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="lgModal.hide()">
            <span aria-hidden="true" style="font-size: 30px">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <carousel
            interval="0"
            class="my-gallery"
            [showIndicators]="false"
            [activeSlide]="activeImage"
          >
            <slide *ngFor="let dier of dieren">
              <img
                [src]="dier.staalRef"
                style="
                  display: block;
                  max-width: 100%;
                  min-width: 500px;
                  max-height: 100%;
                "
              />
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</div>
