<div class="container">
  <h1 class="text-center mb-4">PROJECTEN</h1>
<div class="row justify-content-center">
  <div class="image-container col-lg-5 col-12 mt-4">
    <a routerLink="/projecten/hornbach">
      <div class="d-flex image-container">
        <img class="w-100" src="assets\images\projecten\bbq4.jpg" />
          <div class="text centered">HORNBACH</div>
          </div>
    </a>
  </div>

  <div class="image-container col-lg-5 col-12 mt-4">
    <a routerLink="/projecten/artis">
      <div class="d-flex image-container">
        <img class="w-100" src="assets\images\projecten\artis3.jpg" />
          <div class="text centered">ARTIS</div>
       </div>
    </a>
  </div>
</div>


